import React from "react"
import { Link } from "gatsby"
import Footer from "../components/footer"
import IndexBackgroundSection from "../components/index/index_background_section"
import IndexConsultoriaITSection from "../components/index/index_consultoriait_section"
import IndexServiciosCloudSection from "../components/index/index_servicioscloud_section"
import IndexSeguridadSection from "../components/index/index_seguridad_section"
import IndexDevOpsSection from "../components/index/index_devops_section"
import IndexClientesYSociosSection from "../components/index/index_clientesysocios_section"
import { Helmet } from "react-helmet"
import ISO from "../../static/images/certificados/applus_iso.svg"
import "../styles/style.css"


export default () => (
  <div>
    <Helmet>
      <title>Cloud, DevOps, Seguridad, Soporte Informático | Qualoom</title>
      <meta
        name="description"
        content="Empresa especializada en Cloud (Partner Advanced de AWS, GCP, Azure), DevOps, Seguridad informática, Consultoría tecnológica y Soporte informático 24x7"
      />
      <html lang='es'/>      
      <link rel="canonical" href="https://www.qualoom.es/"/>

      <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
      <meta property="og:locale" content="es_ES"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="Cloud, DevOps, Seguridad, Soporte Informático | Qualoom"/>
      <meta property="og:description" content="Empresa especializada en Cloud (Partner Advanced de AWS, GCP, Azure), DevOps, Seguridad informática, Consultoría tecnológica y Soporte informático 24x7"/>
      <meta property="og:url" content="https://www.qualoom.es/"/>
      <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/746PlOCgPxAF0ANMBsPOJc/fb50c5538bcbe9b545182f6c2426076e/tw_cards_home.png"/>
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="628"/>
        
        {/*Twitter*/}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/746PlOCgPxAF0ANMBsPOJc/fb50c5538bcbe9b545182f6c2426076e/tw_cards_home.png"/>
      <meta property="twitter:creator" content="https://twitter.com/qualoom"/>
      <meta property="fb:admins" content="338114870907726"/>

      <script type="application/ld+json">
        {`
        {"@context":"https://schema.org", "@type": "ProfessionalService", "url":"https://www.qualoom.es/", 
        "name": "Qualoom Expertise Technology", "logo":"https://www.qualoom.es/static/logo-menu-9dd0a84787cd6b500427c94545e9460a.png"},
        "description": "Empresa especializada en Cloud (Partner Advanced de AWS, GCP, Azure), DevOps, Seguridad informática, Consultoría tecnológica y Soporte informático 24x7"}
        {"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/#breadcrumb",              
          "itemListElement":[{"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}}]}
        `}
      </script>
    </Helmet>
    <IndexBackgroundSection />
    <Link to="/normativa-iso-27001/">
      <div className="bg-image-iso"><div className="main_left">
        <div className="iso-banner">
          <img src={ISO} alt="Certificación Seguridad de la Información - ISO 27001:2022"></img>
          <div className="iso-text">
            <span>Compromiso con la Seguridad</span>
            <span>Certificado ISO 27001</span>
            <span>Seguridad de la Información</span>
          </div>
        </div>
      </div></div>
    </Link>
    <IndexConsultoriaITSection />
    <IndexServiciosCloudSection />
    <IndexDevOpsSection />
    <IndexSeguridadSection />
    <IndexClientesYSociosSection />
    <Footer />
  </div>
)


import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import "../../styles/index_image.css"

const IndexDevOpsSection = () => {
  const data = useStaticQuery(graphql`
    query Images_3 {
      image: file(
        relativeDirectory: { eq: "index" }
        name: { eq: "desarrollo-software-qualoom" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="container_right">
      <div className="main_right">        
        <div className="content_wrapper">
          <h2>Servicios DevOps</h2><hr />
          <p>DevOps, conjunto de prácticas, métodos y herramientas que mejoran el marco colaborativo entre las áreas de operaciones, desarrollo, calidad y sistemas con el fin de reducir el time-to-market de nuevos productos y servicios, mejorar la calidad de los mismos y reducir los costes productivos de manera continuada.</p><Link to="/devops/">{" "}<button className="descubre">Descubre más</button></Link>
        </div>
        <div className="image_wrapper_right"><Img fluid={data.image.childImageSharp.fluid} alt="Desarrollo Software Qualoom"/></div>
      </div>
    </div>
  )
}

export default IndexDevOpsSection
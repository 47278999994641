import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "../../styles/index_clientesysocios_section.css"

const IndexClientesYSociosSection = () => {
  const data = useStaticQuery(graphql`
    query {
      image: allFile(
        filter: { relativeDirectory: { eq: "logo" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  //console.log(data)
  return (
    <div className="main_clientes">
      <div className="logo_clientes_content">
        <h2>Nuestros principales clientes y socios</h2>
        <p>Nuestra mejor carta de presentación son nuestros clientes y su confianza</p>
      </div>
      <div className="clients_logo">
        {data.image.nodes.map(img => (<div className="child" key={img.id}><Img fluid={img.childImageSharp.fluid} alt="Clientes Qualoom"/></div>))}
      </div>
    </div>
  )
}

export default IndexClientesYSociosSection

import React from "react"
import "../../styles/index_background_section.css"
import Header from "../header"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const IndexBackgroundSection = () => {
  const data = useStaticQuery(graphql`
    query Images_index {
      image: file(
        relativeDirectory: { eq: "index" }
        name: { eq: "0-cabecera-index" }
      ) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Innovación tecnológica basada en la excelencia</h1>
              <p>Especialistas en Consultoría tecnológica, Cloud (Amazon Web Service, Azure, Google Cloud) e infraestructura, DevSecOps, SysOps y Seguridad informática.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default IndexBackgroundSection

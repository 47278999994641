import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import "../../styles/index_image.css"

const IndexServiciosCloudSection = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(
        relativeDirectory: { eq: "index" }
        name: { eq: "logo-qualoom-aws" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  //console.log(data)
  return (
    <div className="container_left">
      <div className="main_left">
        <div className="image_wrapper_left">
          <Img fluid={data.image.childImageSharp.fluid} alt="Servicios Cloud Qualoom"/>
        </div>
        <div className="content_wrapper">
          <h2>Servicios Cloud (AWS, Azure y GCP)</h2><hr />
          <p>Advanced Consulting Partner de Amazon Web Services, Azure y Google Cloud Platform con más de 10 años de experiencia en proyectos de migración a la gran nube, Reseller en todos ellos y miembro del programa de competencias de AWS: Digital Customer Experience Competence y Migration/DevOps Competence permiten que cientos de clientes hayan confiado en nosotros para impulsar, asegurar y evolucionar sus servicios.</p><Link to="/cloud-infraestructura/"><button className="descubre">Descubre más</button></Link>
        </div>
      </div>
    </div>
  )
}

export default IndexServiciosCloudSection

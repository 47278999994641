import React from "react"
import { Link } from "gatsby"
import "../../styles/index_image.css"

const IndexConsultoriaITSection = () => {
    
  return (
    <div className="container_right">
      <div className="main_left">
          <div className="content_wrapper">
            <h2>Consultoría tecnológica</h2><hr />
            <p>Consultoría, asesoramiento y soluciones orientadas a garantizar las necesidades tecnológicas de tu organización, disponiendo de un trato personalizado. <br/>Damos soporte a nuestros clientes de informática tradicional aprovechando nuestra experiencia en gestión de incidencias con soporte personalizado en horario laboral, o en modalidades 12x5 o 24x7.</p><Link to="/consultoria-tecnologica/"><button className="descubre">Descubre más</button></Link>
          </div>
      </div>
    </div>
  )
}
export default IndexConsultoriaITSection


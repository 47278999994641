import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import "../../styles/index_image.css"

const IndexSeguridadSection = () => {
  const data = useStaticQuery(graphql`
    query Images_1 {
      image: file(
        relativeDirectory: { eq: "index" }
        name: { eq: "seguridad-index" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="container_left">
      <div className="main_left">
        <div className="image_wrapper_left"><Img fluid={data.image.childImageSharp.fluid} alt="Seguridad informática"/></div>
        <div className="content_wrapper">
          <h2>Seguridad informática</h2><hr />
          <p>Servicios de auditoría de sistemas, seguridad, ciberseguridad y cumplimiento. Amenazas, vulnerabilidades, ataques, monitorización avanzada para la detección de intrusiones, cambios de recursos fraudulentos y formación son las claves de un servicio 360 que permite a nuestros socios y clientes disponer de una arquitectura de información predecible, más segura y con la capacidad de mitigar la materialización de amenazas.</p><Link to="/seguridad-informatica/"><button className="descubre">Descubre más</button></Link>
        </div>
      </div>
    </div>
  )
}

export default IndexSeguridadSection
